import {
  castArray, isEmpty, last, map, omit, toNumber,
} from 'lodash';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import {
  FilterResult, PostSearchCriteria, PostSort,
} from 'lib/types/filters';
import { SearchResultId } from 'lib/types/globalSearch';

const SLUG_DELIMITER = '~';

interface UpdateRouterQueryOptions {
  /**
   * By default, when we update the router query we use `push`.
   * This will change history so the user can click 'back' to return
   * to the previous query.
   *
   * In a few cases (usually where the query param is provided for
   * information or utility), we don't want to change the history.
   * In that case, set `useReplace` to true.
   */
  useReplace?: boolean;
}

/**
 * This is the set of all post options with labels
 */
export const postSortOptions: Record<PostSort, string> = {
  currentEngagements: 'Total Engagements',
  currentLikes: 'Most Likes',
  currentViews: 'Most Views',
  postedAt: 'Most Recent',
  createdAt: 'Most Recent Imports',
  leastViews: 'Least Views',
  leastEngagement: 'Least Engagements',
  oldestPost: 'Oldest',
  relevance: 'Relevance',
};

/**
 * On explore and other users' profiles, users do not have access to
 * sort posts in "reverse".
 */
export const basicPostSortOptions = omit(
  postSortOptions, ['leastViews', 'oldestPost', 'leastEngagement', 'relevance'],
);

export const defaultSort = 'postedAt';

/**
  This maps query and criteria keys that are different.
  If keys are the same, we don't need to put them here.  
*/
const QUERY_CRITERIA_MAP: { [key: string]: keyof PostSearchCriteria } = {
  role: 'byRoleId',
  users: 'byUserId',
  tags: 'tagValueId',
  events: 'eventValueId',
  fileType: 'fileTypes',
  roleTags: 'byRoleTagId',
  postingAccount: 'postingAccounts',
};

/** 
 *  Converts a label and optional id to a slug
 *  e.g. from 'mary-lou', 123 to `mary-lou~123`
 */
export function toSlug(label: string, id?: SearchResultId) {
  // In some cases (posting accounts) the id and label are the same
  // so just return the label.
  if (!id || id === label) {
    return label;
  }
  return `${label}${SLUG_DELIMITER}${id}`;
}

/** Converts a slug (usually inthe form of `label~id`) to an id */
export function slugToId(slug: string) {
  const values = slug.split(SLUG_DELIMITER);
  if (!values.length) {
    return 0;
  }
  // If there is only one item in the split array, assume it's the Id
  if (values.length === 1) {
    return toNumber(values[0]);
  }
  return toNumber(last(values));
}

export function slugToString(slug: string) {
  const values = slug.split(SLUG_DELIMITER);
  return last(values);
}

export function slugToValues(slug?: string) {
  if (!slug) {
    return {
      id: 0,
      label: undefined,
    };
  }
  const values = slug.split(SLUG_DELIMITER);
  if (values.length <= 1) {
    return {
      id: 0,
      label: slug,
    };
  }
  return {
    id: toNumber(last(values)),
    label: values[0],
  };
}

/**
 * queryToCriteria converts query params to criteria used to make an API call
 * @param ParsedUrlQuery
 * @return PostSearchCriteria
 */
export function queryToCriteria(query: ParsedUrlQuery) {
  const criteria: { [key: string]: undefined | string | number | (string | number)[] } = {};
  Object.keys(query).forEach((key) => {
    const criteriaKey = QUERY_CRITERIA_MAP[key] || key;
    const value = query[key];
    if (criteriaKey.includes('Id') && criteriaKey !== 'importId') {
      criteria[criteriaKey] = map(castArray(value), (q: string) => slugToId(q));
    } else if (criteriaKey === 'sort') {
      criteria.sort = postSortOptions[value as PostSort] ? value : defaultSort;
    } else if (criteriaKey === 'list' || criteriaKey === 'itemList') {
      criteria[criteriaKey] = slugToString(value as string);
    } else {
      criteria[criteriaKey] = query[key];
    }
  });
  return criteria;
}

/**
 * queryToFilters
 * @param ParsedUrlQuery e.g. users=freshtape~9
 * @return [{
  id: 9,
  label: 'freshtape',
  subLabel: 'freshtape',
  query: 'users',
  relevancyScore: 1,
}]
 */
export const defaultFilterKeys = ['users', 'postingAccount', 'events', 'tags', 'keyword', 'role', 'excludeUserId', 'excludeEventId'];
export function queryToFilters(
  query: ParsedUrlQuery, filterKeys = defaultFilterKeys,
): FilterResult[] {
  const filters: FilterResult[] = [];
  for (let i = 0; i < filterKeys.length; i += 1) {
    const key = filterKeys[i];
    const q = query[key];
    if (q) {
      castArray(q).forEach((slug) => {
        const { id, label } = slugToValues(slug);
        filters.push({
          id,
          label: label || '',
          subLabel: key,
          query: key,
          relevancyScore: i,
        });
      });
    }
  }

  return filters;
}

/** 
 * getUrlPath returns the base url path without the slash and without query strings
 * e.g. /creators -> creators, /explore?users=someuser -> explore 
*/
export const getUrlPath = (url: string) => {
  const iEnd = url.indexOf('?') > 0 ? url.indexOf('?') : url.length;
  return url.substring(1, iEnd);
};

export function updateRouterQuery(
  router: NextRouter,
  newQuery: ParsedUrlQuery,
  options: UpdateRouterQueryOptions = { useReplace: false },
) {
  const isProfilePage = router.pathname === '/[profileuser]';
  const query = {
    ...router.query,
    page: [], // clear page param
    from: [],
    ...newQuery,
    ...(isProfilePage && { profileuser: getUrlPath(router.asPath) }),
  };

  const newRoute = {
    pathname: router.pathname,
    query,
  };

  if (options.useReplace) {
    router.replace(newRoute, undefined, { shallow: true });
  } else {
  // A shallow push avoids re-running server-side props.
    router.push(newRoute, undefined, { shallow: true });
  }
}

/**
 *  clearQuery takes an array of strings and converts them to an object where the
 *  strings are keys and the values are empty arrays.
*/
export function clearQuery(keys: string[]) {
  const clearedQuery: { [key: string]: string[] } = {};
  keys.forEach((key) => {
    clearedQuery[key] = [];
  });
  return clearedQuery;
}

export function isEmptyProfileQuery(query: ParsedUrlQuery) {
  return (isEmpty(query)
    || (Object.keys(query).length === 1 && !!query.profileuser));
}
